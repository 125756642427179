<template>
  <div>
    <b-row>
      <!-- ENGAGEMENT START -->
      <b-col class="pr-4">
        <b-row class="mb-2">
          <b-col>
            <div class="d-flex" style="gap: 20px">
              <score-bar
                :label="$t('page.results.score.heatmap.scoreBars.engagement')"
                :score="parseInt(emotionScores.emotion_score_kpi.toFixed())"
                :variant="'orange'"
                :hideScore="true"
                :labelSize="'small'"
                :condensed="false"
                :benchmark="benchmark.engagementScore"
              ></score-bar>
              <div class="engagement-box p-2">
                <span class="score-large">{{
                  emotionScores.emotion_score_kpi.toFixed()
                }}</span>
                <span class="score-small">/100</span>
              </div>
            </div>

            <scoreBar
              class="mt-2"
              :score="parseInt(emotionScores.valence_kpi.toFixed())"
              :label="$t('page.results.score.heatmap.scoreBars.valence')"
              :toolTip="$t('page.results.score.heatmap.scoreBars.valence')"
              :variant="'gradient'"
              :min="-100"
              :max="100"
              v-if="showMoreEngagement"
            >
            </scoreBar>
          </b-col>
        </b-row>
        <!-- PIECHART START -->
        <b-row class="mt-3" v-if="showMoreEngagement">
          <b-col>
            <title-info
              condensed
              :info="$t('page.results.score.heatmap.piechart.info')"
            >
              {{ $t("page.results.score.heatmap.piechart.title") }}
            </title-info>
            <donut-graph
              class="mb-3"
              :piechartData="piechartData()"
            ></donut-graph>
          </b-col>
        </b-row>
        <!-- PIECHART END -->
        <button
          variant="light"
          class="no-decoration-btn showMoreHover"
          @click="toggleShowMoreEngagement"
          style="color: var(--emotiva-pink); width: 100%;"
        >
          <b-row class="mt-2 mb-5 showMoreRow" >
            <b-col style="display: flex; justify-content: flex-start">
                {{
                  !showMoreEngagement
                    ? $t("button.showMore")
                    : $t("button.showLess")
                }}
            </b-col>
            <b-col style="display: flex; justify-content: flex-end">
              <b-icon
                :rotate="showMoreEngagement ? '180' : '0'"
                icon="chevron-down"
                font-scale="1.2"
                style="cursor: pointer"
              ></b-icon>
            </b-col>
          </b-row>
        </button>
      </b-col>
      <!-- ATTENTION METRICS START -->
      <b-col class="pr-4">
        <div class="d-flex" style="gap: 20px">
          <score-bar
            :label="$t('page.results.score.heatmap.scoreBars.attention')"
            :score="saliencyScores.attentionScore"
            :benchmark="benchmark.attentionScore"
            :hideScore="true"
            :labelSize="'small'"
            :condensed="false"
          ></score-bar>
          <div class="attention-box p-2">
            <span class="score-large">{{ saliencyScores.attentionScore }}</span>
            <span class="score-small">/100</span>
          </div>
        </div>
        <div v-if="showMoreAttention">
          <score-bar
            class="mt-2"
            :label="$t('page.results.score.heatmap.scoreBars.clarity')"
            :score="saliencyScores.clarityScore"
            :benchmark="benchmark.clarityScore"
            :toolTip="$t('page.results.score.heatmap.toolTip.clarity')"
          ></score-bar>
          <score-bar
            class="mt-5"
            :label="$t('page.results.score.heatmap.scoreBars.cognitiveDemand')"
            :score="saliencyScores.mentalDemand"
            :benchmark="benchmark.mentalDemand"
            :toolTip="$t('page.results.score.heatmap.toolTip.cognitiveDemand')"
          ></score-bar>
        </div>
        <button
          variant="light"
          class="no-decoration-btn showMoreHover"
          @click="toggleShowMoreAttention"
          style="color: var(--emotiva-pink); width: 100%;"
        >
          <b-row class="mt-3 showMoreRow">
            <b-col style="display: flex; justify-content: flex-start">
              
                {{
                  !showMoreAttention
                    ? $t("button.showMore")
                    : $t("button.showLess")
                }}
            </b-col>
            <b-col style="display: flex; justify-content: flex-end">
              <b-icon
                :rotate="showMoreAttention ? '180' : '0'"
                icon="chevron-down"
                font-scale="1.2"
                style="cursor: pointer"
              ></b-icon>
            </b-col>
          </b-row>
        </button>
      </b-col>
      <!-- ATTENTION METRICS END -->
    </b-row>
    <!-- NOTE -->
    <!-- <b-row>
      <b-col style="display: flex; flex-direction: column;">
        <hr />
        <h5 style="font-weight: 400;">Note</h5>
        <b-form-textarea placeholder="Note something" v-model="note" id="note"></b-form-textarea>
        <b-button class="mt-2" style="width: auto; align-self: flex-end;" @click="addNote">
          {{ $t("button.save") }}
        </b-button>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import scoreBar from "./scoreBar.vue";
import TitleInfo from "../Score/TitleInfo.vue";
import DonutGraph from "../Score/DonutGraph.vue";
import * as am4core from "@amcharts/amcharts4/core";

export default {
  components: {
    scoreBar,
    TitleInfo,
    DonutGraph,
  },
  data() {
    return {
      sliderValue: 50, // Default value for the first slider
      gradientSliderValue: 50, // Default value for the second slider
      showBubble: false,
      showGradientBubble: false,
      showMoreAttention: false,
      showMoreEngagement: false,
      gradientSliderStyle: {},
      metrics: [],
      note: "",
    };
  },
  props: {
    saliencyScores: {
      type: Object,
      required: true,
      // validator: function (value) {
      //   // Check if the object contains all the required keys
      //   const requiredKeys = ['attentionScore', 'clarityScore', 'engagementScore', 'valenceScore', 'cognitiveDemandScore'];
      //   return requiredKeys.every(key => key in value && typeof value[key] === 'number');
      // },
    },
    benchmark: {
      type: Object,
      required: true,
    },
    emotionScores: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleShowMoreAttention() {
      this.showMoreAttention = !this.showMoreAttention;
    },
    toggleShowMoreEngagement() {
      this.showMoreEngagement = !this.showMoreEngagement;
    },
    piechartData() {
      const ret = [
        {
          value: 100 * this.emotionScores.amusement,
          label: this.$t("page.results.score.heatmap.piechart.label.amusement"),
          color: am4core.color("#ff66a2"),
        },
        {
          value: 100 * this.emotionScores.anger,
          label: this.$t("page.results.score.heatmap.piechart.label.anger"),
          color: am4core.color("#7c7c7c"),
        },
        {
          value: 100 * this.emotionScores.awe,
          label: this.$t("page.results.score.heatmap.piechart.label.awe"),
          color: am4core.color("#ffda40"),
        },
        {
          value: 100 * this.emotionScores.contentment,
          label: this.$t(
            "page.results.score.heatmap.piechart.label.contentment"
          ),
          color: am4core.color("#00fa9a"),
        },
        {
          value: 100 * this.emotionScores.disgust,
          label: this.$t("page.results.score.heatmap.piechart.label.disgust"),
          color: am4core.color("#a974bc"),
        },
        {
          value: 100 * this.emotionScores.excitement,
          label: this.$t(
            "page.results.score.heatmap.piechart.label.excitement"
          ),
          color: am4core.color("#ff4040"),
        },
        {
          value: 100 * this.emotionScores.fear,
          label: this.$t("page.results.score.heatmap.piechart.label.fear"),
          color: am4core.color("#a9dd4d"),
        },
        {
          value: 100 * this.emotionScores.sadness,
          label: this.$t("page.results.score.heatmap.piechart.label.sadness"),
          color: am4core.color("#4A5459b0ff68"),
        },
      ];
      return ret;
    },
    addNote() {},
  },
  mounted() {},
};
</script>

<style scoped>
.attention-box {
  background-color: rgba(99, 0, 238, 0.2);
  height: 64px;
  width: 150px;
  color: #6200ee;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.engagement-box {
  background-color: rgba(214, 122, 127, 0.2);
  height: 64px;
  width: 150px;
  color: #fe9d35;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-large {
  opacity: 1;
  font-size: 40px;
  /* 2.5 times larger */
  margin-right: 5px;
  font-weight: bold;
}

.score-small {
  opacity: 1;
  margin-top: 16px;
  font-size: 16px;
  font-weight: bold;
}

#note {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #cbd2e0;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 16px;
  resize: none;
}

.showMoreHover{
  :hover{
    background-color: rgb(246, 246, 246);
    border-radius: 5px;
  };
}
.showMoreRow{
  height: 40px;
  align-items: center;
}
</style>
